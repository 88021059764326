@font-face {
    font-family: 'Michroma';
    src: url(./assets/fonts/Michroma-Regular.ttf);
}

@font-face {
    font-family: 'Arimo';
    src: url(./assets/fonts/Arimo-VariableFont_wght.ttf);
}

@keyframes fadeInEffect {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.about-section {
    text-align: center;
    padding: 20px;
    margin: 2.5% 15% 5%;
    animation: fadeInEffect 0.75s ease-out forwards;

    @media screen and (max-width: 1200px) {
        margin: 2.5% 7.5% 5%;
    }

    @media screen and (max-width: 992px) {
        margin: 2.5% 2.5% 5%;
    }

    @media screen and (max-width: 768px) {
        margin: 2.5% 0 5%;
    }
}

.about-section-title {
    font-family: Michroma;
    text-transform: uppercase;
    font-size: 25px;
    color: #4b4b4b;
    margin-bottom: 4%;
    margin-top: 0%;

    @media screen and (max-width: 1200px) {
        font-size: 21px;
        margin-bottom: 7%;
    }

    @media screen and (max-width: 992px) {
        font-size: 17px;
        margin-bottom: 7%;
    }

    @media screen and (max-width: 768px) {
        font-size: 16px;
        order: 3; // Adjust this if necessary based on layout
        word-wrap: break-word; // Allow long words to be broken and wrapped to the next line
        white-space: normal; // Ensure white space is handled normally, allowing wrapping
        margin-bottom: 7%;
    }
}

.about-content-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    // Switch to column layout on mobile
    @media screen and (max-width: 768px) {
        flex-direction: column;
    }
}

.about-photo-section,
.about-text-section {
    flex: 1;
    padding: 0 10px;

    // Ensure full width on mobile for proper stacking
    @media screen and (max-width: 768px) {
        width: 100%;
    }
}

.about-photo-section {
    order: 3;
    max-width: 30%;

    @media screen and (max-width: 768px) {
        order: 1;
        max-width: 60%;
        margin-bottom: 4%;
    }

    img {
        max-width: 100%;
        height: auto;
        display: block;
        margin: 0 auto;
        margin-bottom: 5%;
    }
}

.about-text-section {
    font-size: 18px;
    font-family: Arimo;
    color: #4b4b4b;
    order: 2;
    text-align: left;
    max-width: 90%;

    p {
        margin-bottom: 3%;
    }

    @media screen and (max-width: 1200px) {
        font-size: 17px;
    }

    @media screen and (max-width: 992px) {
        font-size: 14px;
    }

    // Text last on mobile
    @media screen and (max-width: 768px) {
        font-size: 12px;
        order: 3;
    }

    a {
        margin-bottom: 3%;
        color: #4b4b4b;
    }

    @media screen and (max-width: 1200px) {
        font-size: 18px;
    }

    @media screen and (max-width: 992px) {
        font-size: 16px;
    }

    // Text last on mobile
    @media screen and (max-width: 768px) {
        font-size: 12px;
        order: 3;
    }
}