@font-face {
    font-family: 'Michroma';
    src: url(./assets/fonts/Michroma-Regular.ttf);
}

@keyframes fadeInEffect {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.logo {
    margin: 0;
    font-size: 24px;
    font-family: 'Michroma', sans-serif; // Apply the Michroma font
    display: flex;
    justify-content: center;
    align-items: center;
}

.logo-image {
    width: 100%;
    height: auto;
    margin-left: 40px;
    max-width: 300px;
    align-items: center;

    @media (max-width: 1200px) {
        max-width: 250px;
        margin-left: 30px;
    }

    @media (max-width: 992px) {
        max-width: 200px;
        margin-left: 20px;
        margin-bottom: 7.5px;
    }

    @media (max-width: 768px) {
        margin-left: 15px;
        max-width: 150px;
        margin-bottom: 7.5px;
    }
}

.top-bar {
    display: flex;
    justify-content: flex-start;
    padding: 6px; // Adjust the padding as needed
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.social-links {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    // Add this line if not already present
    flex-direction: row;
}

.instagram-icon {
    display: flex;
    align-items: center;
    font-size: 1.75em; // Adjust the size of the Instagram icon
    color: #d0d0d0; // Adjust the color of the Instagram icon
    transition: color 1s ease;
    margin-left: 35px;

    @media (max-width: 768px) {
        margin-left: 10px; // Adjusted margin for mobile devices
    }

    &:hover {
        color: #6e6e6e;
        transition: color 0.5s ease;
    }
}

.linkedin-icon {
    display: flex;
    align-items: center;
    font-size: 1.75em; // Adjust the size of the Instagram icon
    color: #d0d0d0; // Adjust the color of the Instagram icon
    transition: color 1s ease;
    margin-left: calc(2px + 2%);

    @media (max-width: 768px) {}

    &:hover {
        color: #6e6e6e;
        transition: color 0.5s ease;
    }
}

.header {
    background-color: #ffffff;
    color: #fff;
    animation: fadeInEffect 0.75s ease-out forwards;
}

.container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
}

.header-content {
    margin-top: calc(10px + 0.5%);
    margin-bottom: calc(30px + 0.5%);
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 100%;

    @media screen and (max-width: 1200px) {
        margin-top: 2.5%;
        margin-bottom: 4%;
    }
}

.logo h1 {
    margin: 0;
    font-size: 36px;
}

nav ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

nav ul li {
    display: inline;
    margin-right: 20px;
}

nav ul li:last-child {
    margin-right: 0;
}

nav ul li a {
    color: #fff;
    text-decoration: none;
}

.main-navigation-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 768px) {
        position: relative; // Ensure relative positioning for menu toggle
    }
}

.main-navigation {
    display: flex;
    justify-content: flex-end;
    margin-right: 55px;

    @media (max-width: 1200px) {
        display: none; // Hide the navigation by default on smaller screens
        flex-direction: column; // Stack items vertically
        position: absolute; // Position the dropdown menu
        top: 100%; // Align dropdown menu below the header
        left: 0; // Align dropdown menu to the left
        background-color: #ffffff; // Background color for the dropdown menu
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); // Add shadow for dropdown menu
        width: 100%; // Set full width for the dropdown menu
        padding: 10px; // Add padding to the dropdown menu
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
    }

    li {
        margin-right: 40px; // Add space between navigation items
    }

    a {
        color: #4b4b4b;
        font-size: 16px;
        font-family: 'Michroma', sans-serif;
        text-decoration: none;
        transition: color 0.5s ease;

        @media screen and (max-width: 1200px) {
            font-size: 14px;
        }

        @media screen and (max-width: 992px) {
            font-size: 13px;
        }

        @media screen and (max-width: 768px) {
            font-size: 12px;
        }

        &:hover {
            color: #929292;
        }
    }
}


.menu-toggle {
    display: none; // Hide the menu toggle button by default
    color: #4b4b4b;
    font-size: 20px;
    align-items: center;
    font-family: 'Michroma', sans-serif;

    @media (max-width: 1200px) {
        display: flex;
        align-items: center;
        cursor: pointer; // Change cursor to pointer on hover
        margin-right: 18px;
    }

    @media (max-width: 992px) {
        font-size: 15px;
    }

    @media (max-width: 768px) {
        font-size: 12px;
    }
}

.dropdown-navigation {
    display: block;
    flex-direction: column; // Stack items vertically
    width: 90%; // Set full width for the dropdown menu
    margin-left: 5%;
    margin-right: 5%;
    text-align: center;
    font-size: 15px;
    border-bottom: 1px solid #ccc;
    margin-bottom: 0px;
    opacity: 0;
    height: 0px;
    overflow: hidden;
    transition: opacity 0.5s ease, height 0.5s ease, margin-bottom 0.5s ease;

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        flex-direction: column; // Stack items vertically
    }

    li {
        margin-right: 0; // No margin between navigation items
        padding-bottom: 16px; // Add space between navigation items vertically
        padding-top: 14px;
        border-top: 1px solid #ccc;
    }

    a {
        color: #4b4b4b;
        font-size: 12px;
        font-family: 'Michroma', sans-serif;
        text-decoration: none;
        transition: color 0.5s ease;

        &:hover {
            color: #929292;
        }
    }

    &.menu-open {
        height: 268.5px; // Set height when menu is open
        opacity: 1;
        display: block;
        margin-bottom: 40px;

        @media (max-width: 1200px) {
            display: block; // Show the menu toggle button on smaller screens
            position: relative;
        }
    }
}