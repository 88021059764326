@font-face {
    font-family: 'Michroma';
    src: url(./assets/fonts/Michroma-Regular.ttf);
}

@keyframes fadeInEffect {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.contact-section-title {
    font-family: Michroma;
    text-transform: uppercase;
    font-size: 25px;
    color: #4b4b4b;
    margin-bottom: 40px;
    margin-top: 0%;

    @media screen and (max-width: 1200px) {
        font-size: 21px;
        margin-bottom: 30px;
    }

    @media screen and (max-width: 992px) {
        font-size: 17px;
        margin-bottom: 30px;
    }

    @media screen and (max-width: 768px) {
        font-size: 16px;
        word-wrap: break-word; // Allow long words to be broken and wrapped to the next line
        white-space: normal; // Ensure white space is handled normally, allowing wrapping
        margin-bottom: 30px;
    }
}

.contact-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* Use the full height of the viewport */
    text-align: center;
    /* Center text for any direct child text elements */
    animation: fadeInEffect 0.75s ease-out forwards;
    font-family: Michroma;
    color: #4b4b4b;
    min-height: 83vh;
    margin-top: 2.5%;
}

/* Additional styling for form and its fields */
form {
    width: 500px;
    /* Adjust this as needed, e.g., max-width: 600px; */
    max-width: 90%;
    /* Maximum width of the form */
    font-family: Michroma;
    color: #4b4b4b;
}

message-form {
    width: 500px;
    max-width: 90%;
    font-family: Michroma;
    color: #4b4b4b;
}

.form-field {
    margin-bottom: 20px;
    font-family: Michroma;
    color: #4b4b4b;
}

.form-field input,
.form-field textarea {
    width: 100%;
    /* Ensure inputs take full width of the form */
    padding: 10px;
    border: 1px solid #4b4b4b;
    border-radius: 2.5px;
    color: #4b4b4b;
    margin-bottom: 1%;
    font-size: 18px;

    @media screen and (max-width: 1200px) {
        font-size: 16px;
    }

    @media screen and (max-width: 992px) {
        font-size: 13px;
    }
}

.form-field label {
    margin-bottom: 1%;
    font-size: 18px;

    @media screen and (max-width: 1200px) {
        font-size: 16px;
    }

    @media screen and (max-width: 992px) {
        font-size: 13px;
    }
}

button[type="submit"] {
    margin-top: 2.5%;
    padding: 10px 20px;
    background-color: transparent;
    font-family: Michroma;
    color: #4b4b4b;
    border: 1.75px solid #4b4b4b;
    border-radius: 5px;
    cursor: pointer;
    transition: color 0.75s ease, background-color 0.75s ease;
    font-size: 18px;

    @media screen and (max-width: 1200px) {
        font-size: 16px;
    }

    @media screen and (max-width: 992px) {
        font-size: 13px;
    }
}

button[type="submit"]:hover {
    color: white;
    background-color: #4b4b4b;
    transition: color 0.5s ease, background-color 0.5s ease;
}

.contact-info {
    text-align: left;
    margin-bottom: 40px;
    font-family: Michroma;
    color: #4b4b4b;
    font-size: 18px;

    @media screen and (max-width: 1200px) {
        font-size: 16px;
        margin-bottom: 30px;
    }

    @media screen and (max-width: 992px) {
        font-size: 13px;
        margin-bottom: 30px;
    }
}

.contact-info p {
    margin-bottom: 0.5%;
}

.thank-you-message {
    font-family: Michroma;
    color: #4b4b4b;
    font-size: 18px;
    font-weight: bold;
    animation: fadeInEffect 0.75s ease-out forwards;

    @media screen and (max-width: 1200px) {
        font-size: 16px;
        margin-bottom: 4%;
        margin-top: 2%;
    }

    @media screen and (max-width: 992px) {
        font-size: 13px;
        margin-bottom: 4%;
        margin-top: 2%;
    }
}